export default {
  userConsoleRootRoute: '/userconsole',

  /**
   * Type of alert
   */
  alertTypes: {
    error: 'error',
    success: 'success',
  },

  authURLs: {
    admin: '/auth',
    adminIdentity: '/auth/identity',
    submitEmail: '/userconsole/auth/identity',
    user: '/userconsole/auth',
    durt: '/userconsole/auth/go',
  },

  /**
   * Names of login button types
   */
  buttonTypes: {
    chooseOS: 'chooseOS',
    error: 'error',
    success: 'success',
  },

  /**
   * Names of the different client types
   */
  clientType: {
    admin: 'admin',
    user: 'user',
  },

  /**
   * Name of cookie that determines whether the user or admin page is displayed
   */
  clientTypeCookie: 'jc_prevLoginType',

  documentTitles: {
    adminOidcConsent: 'JumpCloud - Consent',
    adminPortalLogin: 'JumpCloud Admin Portal - Login',
    adminPortalTotpSetup: 'JumpCloud Admin Portal - TOTP Setup',
    userPortalLogin: 'JumpCloud User Portal - Login',
    mfaGeneralTitle: 'Verify Your Identity',
  },

  errorParamTypes: {
    googleLoginError: 'googleLoginError',
    mfaExpired: 'mfaExpired',
    pushDenied: 'pushDenied',
    pwdResetDenied: 'pwdResetDenied',
  },

  successParamTypes: {
    pwdResetSuccess: 'pwdResetSuccess',
  },

  formElements: {
    formRef: 'formComponent',
    confirmPassword: {
      vvName: 'confirm password',
      errorMessage: 'Passwords do not match.',
    },
  },

  googleTrackingEvent: { event: 'new-google-signup' },

  loginRoutes: {
    admin: '/login/admin',
    ssoStepUp: '/login/stepup',
    user: '/login',
  },

  oauth: 'oauth',
  passwordReset: 'passwordReset',

  oidc: {
    error: '/oidc/error',
  },

  /**
   * Messages that the UI displays after responses are received
   */
  responseMessages: {
    access_denied: 'There is an issue with this account. Please contact your IT admin.',
    adminGoogleRegistrationError: 'Error occurred while creating your account.',
    federation_error: 'An identity provider error has occurred. Please try again, and contact your IT admin if the error persists.',
    goError: 'The JumpCloud Go service is not available. Please try again, or login using your password.',
    goIdPError: 'JumpCloud Go can\'t be used with an Identity Provider login. Please re-enter your email to continue.',
    invalidCsrf: 'ForbiddenError: invalid csrf token',
    invalidCsrfOidc: 'Forbidden: invalid csrf token',
    loginError: 'Authentication failed.',
    mfaExpired: 'Multi-Factor Authentication expired. Please try to reset your password again.',
    mfaRequired: 'MFA required.',
    mfaUnavailable: 'No identity verification methods are available. Please contact your IT Admin to reset your MFA.',
    mfaUnavailableForPwdReset: 'Password Reset failed because there are no available Multi-Factor Authentication methods. Please reach out to your IT Admin to reset your password.',
    passwordRequired: 'Password required',
    passwordResetError: 'Password reset could not be completed at this time.',
    passwordResetErrorLimit: 'Authentication has failed. Enter your email here and on the next screen select the Reset User Password link.',
    passwordResetSuccess: 'Password reset was successful.',
    server_error: 'An internal error has occurred. Please report this to your IT admin.',
    totpResetError: 'Could not initiate a TOTP reset',
    totpResetErrorLimit: 'TOTP reset attempts have been exceeded. Log in to the admin console to retry',
    totpResetSuccess: 'MFA reset was successful.',
    totpServerError: 'Something went wrong. Please try again, and if this persists reach out to JumpCloud Support.',
    userRegistrationError: 'Registration could not be completed at this time.',
    invalidOtpError: 'Verification code was invalid or already used.',
    unexpected_error: 'An unexpected error occurred. Please try again.',
    webauthnError: 'Failed to verify security key.',
    lockedError: 'Account locked due to too many failed login attempts. Please reset your password.',
    lockedTemp: 'For security reasons your account has been temporarily locked. Please try again in %s minutes.',
  },

  pushStatusMessages: {
    errored: 'There was an error while sending the request. Please try again.',
    expired: 'Request timed-out. Make sure notifications are enabled and try again.',
    pushDenied: 'Login request was denied. Please try again.',
    pwdResetDenied: 'Password Reset request was denied. Please try to reset your password again.',
    pwdResetSuccess: 'Password Reset was successful.',
  },

  routeGeneratorError: 'RouteGenerator was passed empty argument(s)',

  sso: 'sso',
};
